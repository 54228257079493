import React from "react"
import Layout from "../components/layout"
import Events from "../components/Events"

const EventsPage = props => {
  return (
    <Layout
      title="Évenements"
      {...props}
      description="Tout les évènements à venir seront afficher ici"
    >
      <Events />
    </Layout>
  )
}

export default EventsPage
