import React from "react"
import { getValueD9, renameImage, limitText, sort } from "../utils"
import Card from "./UI/Card"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
const Events = ({ limit }) => {
  limit = limit || false
  const data = useStaticQuery(graphql`
    {
      groupSousSite(
        relationships: {
          group_content__sous_site_group_node_ss_event: {
            elemMatch: { relationships: { entity_id: { status: { eq: true } } } }
          }
        }
      ) {
        id
        label
        relationships {
          group_content__sous_site_group_node_ss_event {
            relationships {
              entity_id {
                path {
                  alias
                }
                body {
                  processed
                  summary
                }
                title
                relationships {
                  field_cover {
                    relationships {
                      field_media_image {
                        url
                        uri {
                          url
                          value
                        }
                      }
                    }
                  }
                  field_event_collection {
                    field_dates {
                      end_value
                      value
                    }
                    relationships {
                      field_commune {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const items = getValueD9(
    data,
    "groupSousSite.relationships.group_content__sous_site_group_node_ss_event",
    []
  )
  if (items.length > 0) {
    return (
      <>
        {sort(
          items,
          "relationships.entity_id.relationships.field_event_collection.0.field_dates.value",
          "DESC"
        )
          .filter((node, i) => (limit && i < limit) || true)
          .map((node, i) => {
            return (
              <Card
                key={i}
                Title={getValueD9(node, "relationships.entity_id.title", "value", "")}
                Content={
                  <p>
                    {limitText(
                      getValueD9(node, "relationships.entity_id.body.processed", "").replace(
                        /&nbsp;/g,
                        ""
                      ),
                      60
                    )}
                    ...
                  </p>
                }
                Img={renameImage(
                  getValueD9(
                    node,
                    "relationships.entity_id.relationships.field_cover.relationships.field_media_image.uri.value",
                    false
                  ),
                  null,
                  "square_event_home"
                )}
                SubTitle={
                  "Du " +
                  moment(
                    getValueD9(
                      node,
                      "relationships.entity_id.relationships.field_event_collection.0.field_dates.value",
                      ""
                    )
                  ).format("DD/MM/YYYY") +
                  " au " +
                  moment(
                    getValueD9(
                      node,
                      "relationships.entity_id.relationships.field_event_collection.0.field_dates.end_value",
                      ""
                    )
                  ).format("DD/MM/YYYY") +
                  " - " +
                  getValueD9(
                    node,
                    "relationships.entity_id.relationships.field_event_collection.0.relationships.field_commune.name",
                    ""
                  )
                }
                link={getValueD9(node, "relationships.entity_id.path.alias", "/")}
              />
            )
          })}
      </>
    )
  } else {
    return <p>Pas d'évènements disponibles</p>
  }
}

export default Events
